<template>
<div>
    <blog-form 
    :is-blog-form-sidebar-active.sync="isBlogFormSidebarActive"
        :blog-data="blogData"
        @refetch-data="refetchData" 
    />
    <b-card no-body>
        <div class="m-2">
            <b-row>
                <b-col
                    cols="12"
                    md="6"
                    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                >
                
                </b-col>
                <b-col
                    cols="12"
                    md="6"
                >
                    <div class="d-flex align-items-center justify-content-end">
                        <v-select
                            id="category_id"
                            class="d-inline-block width-350 mr-1"
                                v-model="catFilter"
                                placeholder="Filter By Category"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                label="title"
                                :reduce="(option) => option.id"
                                :options="catsData"
                        />
                        <b-form-input
                            v-model="searchQuery"
                            class="d-inline-block mr-1"
                            placeholder="Search..."
                        />
                        <b-button
                        variant="primary"
                        @click="updateBlog()"
                        >
                            <span class="text-nowrap">Add Blog</span>
                        </b-button>
                    </div>
                </b-col>
            </b-row>
        </div>
        <b-table
                ref="refCatListTable"
                :items="fetchData"
                responsive
                :fields="tableColumns"
               
                :sort-by.sync="sortBy"
                show-empty
                empty-text="No matching records found"
                :sort-desc.sync="isSortDirDesc"
                class="position-relative"
        >
        <template #cell(cover_image)="data">
            
            <b-img-lazy
            
            :src="(data.item.cover_image?data.item.fullpath:require('@/assets/images/no-image.png'))"
            height="110"
            width="170"
            class="rounded m-0"
            />
        </template>
        <template #cell(is_active)="data">
                <b-badge
                    pill
                    :variant="`light-${resolveCatStatusVariant(data.item.is_active)}`"
                    class="text-capitalize"
                >
                    {{ resolveCatStatusnameVariant(data.item.is_active) }} 
                </b-badge>
            </template>
            <template #cell(action)="data">
                <b-button  v-ripple.400="'rgba(113, 102, 240, 0.15)'"  @click="updateBlog(data.item)" variant="outline-success">Edit</b-button>
                <b-button  v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="ml-1" @click="deleteconfirm(data.item.id)" variant="outline-danger">Delete</b-button>

            </template>
        </b-table>
        <div class="mx-2 mb-2">
            <b-row>
                <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-start"
                >
                    <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
                </b-col>
                <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-end"
                >
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalBlogs"
                        :per-page="perPage"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                    >
                        <template #prev-text>
                            <feather-icon
                                icon="ChevronLeftIcon"
                                size="18"
                            />
                            </template>
                            <template #next-text>
                            <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                            />
                        </template>
                    </b-pagination>
                </b-col>
            </b-row>
        </div>
    </b-card>
</div>
</template>
<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BImg,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,VBModal,BFormGroup,BFormCheckbox,BImgLazy
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
//api
import axios from '@axios'
import store from '@/store'
import { ref,onUnmounted } from '@vue/composition-api'
import useBlogList from './useBlogList'
import blogStoreModule from '../blogStoreModule'
import BlogForm from '../blog-form/BlogForm.vue'
import vSelect from 'vue-select'
export default {
    components:{
        BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
        BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,VBModal,BFormGroup,BFormCheckbox,
        BlogForm,BImg,BImgLazy,
        vSelect
    },
    directives:{
        'b-modal': VBModal,
        Ripple

    },
    setup(){
        const CAT_STORE_MODULE_NAME = 'app-blog';
        if (!store.hasModule(CAT_STORE_MODULE_NAME)) store.registerModule(CAT_STORE_MODULE_NAME, blogStoreModule)
        onUnmounted(() => {
            if (store.hasModule(CAT_STORE_MODULE_NAME)) store.unregisterModule(CAT_STORE_MODULE_NAME)
        });
        const {
            blogData,
            isBlogFormSidebarActive,
            refCatListTable,
            tableColumns,
            perPage,
            totalBlogs,
            currentPage,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            statusFilter,
            dataMeta,
            refetchData,
            fetchData,
            resolveCatStatusVariant,
            resolveCatStatusnameVariant,
            updateBlog,
            deleteData,
            catsData,
            catFilter
        } = useBlogList();
        return {
            blogData,
            isBlogFormSidebarActive,
            refCatListTable,
            tableColumns,
            perPage,
            totalBlogs,
            currentPage,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            statusFilter,
            dataMeta,
            refetchData,
            fetchData,
            resolveCatStatusVariant,
            resolveCatStatusnameVariant,
            updateBlog,
            deleteData,
            catsData,
            catFilter
        }


    },
    methods:{
        deleteconfirm(id){
            this.$bvModal
                .msgBoxConfirm('Please confirm that you want to delete Blog ID.'+id, {
                title: 'Please Confirm',
                size: 'sm',
                okVariant: 'danger',
                okTitle: 'Yes',
                cancelTitle: 'No',
                cancelVariant: 'outline-secondary',
                hideHeaderClose: false,
                centered: true,
                })
                .then(value => {
                if(value === true){
                this.deleteData(id)
                }
            
                //this.deleteData(id)
                })

        },
    }
}

</script>

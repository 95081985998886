import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default function useBlogList() {
    const isBlogFormSidebarActive = ref(false)
    const blogData = ref(null);
    
    const toast = useToast()
    const refCatListTable = ref(null);
    const tableColumns = [
        { key: 'cover_image', label: 'Cover Image', sortable: false, tdClass:'width-200 p-1' },
        { key: 'title', label: 'Title', sortable: false },
        { key: 'slug', label: 'Slug', sortable: false },
        { key: 'category_name', label:'Category Name', sortable:false},

        { key: 'is_active', label: 'Status', sortable: false },
        { key: 'action', label: '#', sortable: false },


    ]
    const perPage = ref(10)
    const totalBlogs = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    const statusFilter = ref(null)
    const catFilter = ref(null);
    const catsData = ref([]);
    const dataMeta = computed(() => {
        const localItemsCount = refCatListTable.value ? refCatListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalBlogs.value,
        }
    })
    store.dispatch('app-blog/fetchCats', {})
    .then(response => {
      catsData.value = response.data.data
    })
    const refetchData = () => {
        refCatListTable.value.refresh()
    }
    watch([currentPage, perPage, searchQuery, statusFilter,catFilter], () => {
        refetchData()
    })
    const fetchData = (ctx, callback) => {
        const offset = perPage.value*(currentPage.value-1);
       
        store
          .dispatch('app-blog/fetchBlogs', {
            limit: perPage.value,
            offset:offset,
            keywords: searchQuery.value,
            category_id:catFilter.value
            
              /* page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
            status: statusFilter.value,*/
          })
          .then(response => {
           
            const  cats  = response.data.data
            
            const total = response.data.total?response.data.total:cats.length;
            callback(cats)
            totalBlogs.value = total
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error fetching Category' list",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
    }
    const resolveCatStatusVariant = status => {
       
        if (status) return 'success'
        if (!status ) return 'secondary'
        return 'primary'
    }
    const resolveCatStatusnameVariant = status => {
       
        if (status) return 'Active'
        if (!status) return 'Inactive'
        return 'primary'
    }
    const updateBlog = (data)=>{

        blogData.value = data;
        isBlogFormSidebarActive.value = true;
    }
    const deleteData = (id) => {
        //fetchDeleteProperty
        store.dispatch('app-blog/deleteBlog',id).then(response=>{
          toast({
            component: ToastificationContent,
            props: {
              title: "Delete Success",
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          refetchData();
        }).catch(()=>{
          toast({
            component: ToastificationContent,
            props: {
              title: "Error Delete Blog",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
    
        })
        //console.log('id', id);
        
      }
      
    return {
        isBlogFormSidebarActive,
        blogData,
        refCatListTable,
        tableColumns,
        perPage,
        totalBlogs,
        currentPage,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        statusFilter,
        dataMeta,
        refetchData,
        fetchData,
        resolveCatStatusVariant,
        resolveCatStatusnameVariant,
        updateBlog,
        deleteData,
        catsData,
        catFilter

    }
}